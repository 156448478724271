<template>
  <div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <figure class="mx-auto mb-3">
      <router-link to="/">
        <img
          class="img-fluid"
          src="https://content.pizzahut.com.au/f/49069/x/ea7a70988a/pizza-hut-logo.svg"
          width="200px"
          alt="Pizza Hut Australia Logo"
        />
      </router-link>
    </figure>
    <div v-if="!connection" class="no-connection">
      <div class="text-center">
        <font-awesome-icon :icon="['far', 'wifi-slash']" class="text-primary" />
        <h3>Check your connection and try again.</h3>
        <button class="btn btn-primary px-3" @click="retry()">Retry</button>
      </div>
    </div>
    <div v-else>
      <div class="text-center mx-3">
        <h3 v-if="!isDiscoverErrorArray">
          {{ discoverErrorMsg }}
        </h3>
        <h3 v-for="(error, index) in discoverErrorMsg" v-else :key="index">
          {{ error }}
        </h3>
        <button
          class="btn btn-primary px-3 mt-1"
          :class="{ disabled: retryBtnDisabled }"
          @click="retry()"
        >
          <span v-if="canRetry">Retry</span>
          <span v-else>Retry in &nbsp;{{ counter }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from 'vue'
import { metaDefaults } from '@/services/helpers/meta.helper'
import { isCapacitorNativePlatform } from '@/services/utility/capacitor.utility'
import { useHead } from '@unhead/vue'
import { dictionary } from '@/config/dictionary.config'
import { useFatalStore } from '@/stores/fatal'
import { storeToRefs } from 'pinia'
import { TrackingController } from '@/controllers/tracking.controller'
import { GA4_EVENT } from '@/config/constants'
import { useRoute } from 'vue-router'

useHead({
  ...metaDefaults
})

const props = defineProps({
  failure: {
    type: Error,
    default: null
  }
})

const connection = ref(true)

const route = useRoute()
onMounted(() => {
  // GA4 event tracking
  TrackingController.trackGA4CustomEvent(GA4_EVENT.FATAL_ERROR, {
    page_path: route.fullPath
  })

  logError()
  destroyAppNode()

  if (isCapacitorNativePlatform()) {
    connection.value = window.navigator.onLine
  }

  resetCounter()
  if (didRetry.value) {
    countdownCounter()
  }
  didRetry.value = false
})

const logError = () => {
  // @TODO Log failure to GA properly when GA is hooked with the app.
  console.log(`%c ${props.failure}`, 'color: red; font-weight: bold;')
}

const destroyAppNode = () => {
  if (document.getElementById('app-root')) {
    document.getElementById('app-root').remove()
  }
}

const { canRetry, didRetry, counter, counterIntervalId } = storeToRefs(useFatalStore())
const { countdownCounter, resetCounter } = useFatalStore()

const retry = () => {
  if (canRetry.value) {
    didRetry.value = true
    window.location.reload()
  }
}

const retryBtnDisabled = computed(() => {
  return !canRetry.value
})

onUnmounted(() => {
  clearInterval(counterIntervalId.value)
})

const discoverErrorMsg = ref(dictionary.DISCOVER.GET_ERROR.message)
const isDiscoverErrorArray = Array.isArray(dictionary.DISCOVER.GET_ERROR.message)
</script>

<style lang="scss" scoped>
.fa-pizza-slice,
.fa-wifi-slash {
  font-size: 40px;
}

h3 {
  margin: 20px 0;
}
</style>
