import ProductsApi from '@/services/api/products.api'
import { CacheController } from '@/controllers/cache.controller'
import { ProductController } from '@/controllers/product.controller'
import { config } from '@/config/global.config'
import { defineStore } from 'pinia'
import { filterPizzasWithNoSauce } from '@/services/helpers/product.helper.js'

import { useDiscoverStore } from '@/stores/discover'
import { useCacheStore } from '@/stores/cache'
import { useFeatureFlagsStore } from '@/stores/feature-flags'

export const useProductStore = defineStore('product', {
  state: () => ({
    products: [],
    productInEdit: {},
    voucherInFlight: null,
    productModal: {
      active: false,
      product: {},
      hideSizeBaseOptions: false
    },
    smartFilters: [],
    selectedSmartFilters: []
  }),

  getters: {

  },

  actions: {
    async getCategories (payload) {
      const response = await ProductsApi.GetCategories({
        apiRoot: useDiscoverStore().uris.API_PRODUCT,
        payload
      })

      useCacheStore().setCache({
        expires_at: CacheController.getTimeInXMinutes(config.CACHE_DURATION_IN_MINUTES),
        cache: ProductController.sortCategories(response.data.productCategories),
        cacheType: 'categories'
      })

      useCacheStore().initProductsCache(response.data.productCategories)
    },

    async getProducts (payload) {
      const response = await ProductsApi.GetProducts({
        apiRoot: useDiscoverStore().uris.API_PRODUCT,
        payload: {
          primaryCategory: payload.primaryCategory,
          secondaryCategory: payload.secondaryCategory,
          storeCode: payload.storeCode,
          fulfilmentDateTime: payload.fulfilmentDateTime,
          fulfilmentType: payload.fulfilmentType,
          includeIngredientDetails: true,
          cached: typeof payload.cached === 'undefined' ? true : payload.cached
        }
      })

      const filteredProducts = filterPizzasWithNoSauce(response.data.products)
      const filters = ProductController.defineProductFilters(filteredProducts)
      let subcategorizedProducts = ProductController.subcategorizeProducts(filteredProducts)

      // Override for this feature flag
      const pizzasPageCategoryFlag = useFeatureFlagsStore().getFeatureFlag('pizzas_page_category')
      const pizzasPageCategory = useFeatureFlagsStore().pizzasPageCategory
      subcategorizedProducts = subcategorizedProducts.filter(item =>
        pizzasPageCategoryFlag?.enabled && payload.primaryCategory === 'pizza'
          ? pizzasPageCategory.includes(item.code)
          : !pizzasPageCategory.includes(item.code)
      )

      useCacheStore().setCache({
        expires_at: CacheController.getTimeInXMinutes(config.CACHE_DURATION_IN_MINUTES),
        cache: subcategorizedProducts,
        category: payload.primaryCategory,
        filters,
        cacheType: 'products'
      })

      return subcategorizedProducts
    },

    async getProduct (payload) {
      const apiRoot = useDiscoverStore().uris.API_PRODUCT

      try {
        const response = await ProductsApi.GetProductBySlug({
          apiRoot,
          slug: payload.slug,
          payload: {
            storeCode: payload.storeCode,
            fulfilmentDateTime: payload.fulfilmentDateTime,
            fulfilmentType: payload.fulfilmentType
          }
        })

        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getProductsByProductId (payload) {
      const apiRoot = useDiscoverStore().uris.API_PRODUCT

      try {
        const response = await ProductsApi.GetProductsByProductId({
          apiRoot,
          productId: payload.productId,
          payload: {
            storeCode: payload.storeCode,
            fulfilmentDateTime: payload.fulfilmentDateTime,
            fulfilmentType: payload.fulfilmentType
          }
        })

        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getProductsByProductIds (payload) {
      const apiRoot = useDiscoverStore().uris.API_PRODUCT

      try {
        const response = await ProductsApi.GetProductsByProductIds({
          apiRoot,
          productIds: payload.productIds,
          payload: {
            storeCode: payload.storeCode,
            fulfilmentDateTime: payload.fulfilmentDateTime,
            fulfilmentType: payload.fulfilmentType
          }
        })

        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getSurchargesByProductId (payload) {
      const apiRoot = useDiscoverStore().uris.API_PRODUCT

      try {
        const response = await ProductsApi.GetSurchargesByProductId({ apiRoot, payload })

        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getNavigationItems (payload) {
     const apiRoot = useDiscoverStore().uris.API_PRODUCT

      try {
        const response = await ProductsApi.GetNavigationItems({ apiRoot, payload })

        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(config.CACHE_DURATION_IN_MINUTES),
          cache: response.data,
          cacheType: 'nav_items'
        })

        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getMealById (payload) {
      const apiRoot = useDiscoverStore().uris.API_PRODUCT

      try {
        const response = await ProductsApi.GetMealById({
          apiRoot,
          mealId: payload.mealId,
          payload
        })

        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getMealBySlug (payload) {
      const apiRoot = useDiscoverStore().uris.API_PRODUCT

      try {
        const response = await ProductsApi.GetMealBySlug({
          apiRoot,
          slug: payload.slug,
          payload
        })

        const meal = response.data

        return meal
      } catch (error) {
        return Promise.reject(error)
      }
    },

    setProductInEdit (payload) {
      this.productInEdit = payload
    },

    setVoucherInFlight (payload) {
      this.voucherInFlight = payload
    },

    resetVoucherInFlight () {
      this.voucherInFlight = null
    },

    showProductModal (payload) {
      this.productModal = {
        ...payload,
        active: true
      }
    },

    hideProductModal () {
      this.productModal = {
        active: false,
        product: {},
        hideSizeBaseOptions: false,
        sizeId: null,
        baseId: null
      }
    },

    setSmartFilters (payload) {
      this.smartFilters = payload
    },

    setSelectedSmartFilters (payload) {
      this.selectedSmartFilters = payload
    },

    replaceFilters (payload) {
      const { newFilters, filterType } = payload
      this.smartFilters.find(f => f.filterType === filterType).filters = newFilters
    },

    clearFilters (filterType) {
      this.smartFilters.find(f => f.filterType === filterType).filters.forEach(f => (f.active = false))
    },

    setProductRouteState (payload) {
      this.productRouteState = { ...this.productRouteState, ...payload }
    },

    clearProductRouteState () {
      this.productRouteState = {}
    }
  }
})
