import DiscoverApi from '@/services/api/discover.api'
import { SplashScreen } from '@capacitor/splash-screen'
import { backgroundUpdates, inAppUpdates, liveUpdates, deepLink } from '@/vendor/Appflow'
import { enableCrashlytics } from '@/services/utility/crashlytics.utility'
import { isCapacitorNativePlatform } from '@/services/utility/capacitor.utility'
import { shutdown } from '@/shutdown'
import { start } from '@/main'

/**
 * Fetch configuration from DiscoverApi, then Start app.
 * We pass down an object containing the resolved configration to the start() function.
 * This is so we can immediately dispatch a pinia action and save the response in the store.
 *
 * In the event of failure, we use the hardcoded flags as a backup and then start() the app.
 */
async function getConfiguration() {
  try {
    if (isCapacitorNativePlatform()) {
      // Listen for app updates
      await backgroundUpdates()

      // We can only do In-app updates on Production
      if (import.meta.env.VITE_ENV === 'production') {
        // Check for In-app updates
        await inAppUpdates()
      }

      await liveUpdates()
    }

    await enableCrashlytics()

    const { data: configuration } = await DiscoverApi.getConfiguration()

    deepLink(configuration.uris.URI_WEB_SECONDARY)

    start({ configuration })
  } catch (error) {
    console.log(error)
    
    shutdown({
      reason: error
    })
  } finally {
    SplashScreen.hide()
  }
}

getConfiguration()