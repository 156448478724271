import UserAPI from '@/services/api/user.api.js'
import dayjs from 'dayjs'
import { TrackingController } from '@/controllers/tracking.controller'
import { setCrashlyticsUserIdAndBasketId } from '@/services/utility/crashlytics.utility'
import { changeUser, logCustomEvent } from '@/services/utility/braze.utility'
import { defineStore } from 'pinia'

import { useDiscoverStore } from '@/stores/discover'

export const useUserStore = defineStore('user', {
  state: () => ({
    userId: '00000000-0000-0000-0000-000000000000',
    userStatus: '',
    optOutUserId: '00000000-0000-0000-0000-000000000000'
  }),

  getters: {
    hasUserId: state => !!(state.userId),
  },

  actions: {
    setUser (payload) {
      this.userId = payload

      changeUser(payload)

      TrackingController.trackLegacyEvent('setUserId', {
        userId: payload
      })
      setCrashlyticsUserIdAndBasketId()
    },
    setUserStatus (payload) {
      this.userStatus = payload ? 'new' : 'returning'
    },
    async emailOptOut (userId) {
      this.optOutUserId = userId
      const apiRoot = useDiscoverStore().uris.API_USER
      const response = await UserAPI.EmailOptOut({ apiRoot, userId })
      const user = response.data
      if (user.userId) {
        this.optOutUserId = user.userId
        changeUser(user.userId)
        logCustomEvent('email_unsubscribe', { date: dayjs().format() })
      }
      return response
    },
    async emailOptOutByEmail (data) {
      const apiRoot = useDiscoverStore().uris.API_USER
      const response = await UserAPI.EmailOptOutByEmail({
        apiRoot,
        payload: {
          ...data,
          userId: useUserStore().optOutUserId,
          optoutTimestamp: dayjs().format()
        }
      })
      const user = response.data
      if (user.userId) {
        changeUser(user.userId)
        logCustomEvent('email_unsubscribe', { date: dayjs().format() })
      }
      return response
    },
    async getEntry (payload) {
      const apiRoot = useDiscoverStore().uris.API_USER
      const response = await UserAPI.GetEntry({ apiRoot, payload })
      return response.data
    },
    async smsOptOutShort (shortUserId) {
      const apiRoot = useDiscoverStore().uris.API_USER
      const response = await UserAPI.GetByShortId({ apiRoot, shortUserId })
      const user = response.data
      if (user.userId) {
        changeUser(user.userId)
      } else {
        throw new Error('userId is null or empty')
      }
      logCustomEvent('sms_unsubscribe', { date: dayjs().format() })
      return UserAPI.SMSOptOutShort({ apiRoot, shortUserId })
    },
    async supercarsFanOptOutShort (shortUserId) {
      const apiRoot = useDiscoverStore().uris.API_USER
      const response = await UserAPI.GetByShortId({ apiRoot, shortUserId })
      const user = response.data
      if (user.userId) {
        changeUser(user.userId)
      } else {
        throw new Error('userId is null or empty')
      }
      logCustomEvent('supercars_fan_unsubscribe', { date: dayjs().format() })
      return UserAPI.SupercarsFanOptOutShort({ apiRoot, shortUserId })
    },
    async smsOptOutMobileNumber (payload) {
      const apiRoot = useDiscoverStore().uris.API_USER
      payload.optoutTimestamp = dayjs().format()
      return UserAPI.SMSOptOutMobileNumber({ apiRoot, payload })
    },
    async campaignForm (payload) {
      const apiRoot = useDiscoverStore().uris.API_USER
      const response = await UserAPI.CampaignForm({ apiRoot, payload })

      return response.data
    }
  }
})
